@import 'https://fonts.googleapis.com/css?family=Mulish';

html {
  box-sizing: border-box;
  height: -webkit-fill-available;
  scrollbar-width: none;
}

html::-webkit-scrollbar {
  width: 0;
  height: 0;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100vh;
  /* mobile viewport bug fix */
  min-height: -webkit-fill-available;
  background-color: "#f6f6f9"
}

a {
  text-decoration: none;
}